$(document).on('click', '#GeneralOutBox #OutBoxInput_Button', SendMessageToStore);
$(document).ready(function(){
});
function SendMessageToStore(event){
    event.preventDefault();
    var flag = true;
    var name = $('#OutBoxInput_Name').val();
    var email = $('#OutBoxInput_Email').val();
    var phone = $('#OutBoxInput_Phone').val();
    var message = $('#OutBoxInput_Message').val();
    if(name == '' || name == null){
        alertify.error('Debes ingresar tu nombre');
        flag = false;
    }
    if(email == '' || email == null){
        alertify.error('Debes ingresar tu correo');
        flag = false;
    }else if(email.indexOf('@') == -1){
        alertify.error('Debes ingresar un correo válido');
        flag = false;
    }
    if(flag){
        DataSend = {
            name: name,
            email:email,
            phone:phone,
            message:message
        };
        PostMethodFunction('/WebPagePetitions/Outbox/Send', DataSend, 'Mensaje enviado', OutBoxSendSuccesfully, OutBoxSendSuccesfully);
    }
    
}
function OutBoxSendSuccesfully(result){
    $('#OutBoxInput_Name').val('');
    $('#OutBoxInput_Email').val('');
    $('#OutBoxInput_Phone').val('');
    $('#OutBoxInput_Message').val('');
}